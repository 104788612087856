import React, { useEffect } from 'react';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import { Container } from 'react-bootstrap';
import Form from 'react-bootstrap/Form';

import { useAppDispatch, useAppSelector } from '../app/hooks';
import { selectAddress } from '../features/address/addressSlice';
import { selectSignUp, setAddressUniqueCodeAndUnit } from '../features/signup/signupSlice';

const AddressInput = ({ register, formState: { errors } }: any) => {
  const addr = useAppSelector(selectAddress);
  const signup = useAppSelector(selectSignUp);
  const dispatch = useAppDispatch();

  useEffect(() => {
    dispatch(setAddressUniqueCodeAndUnit({
      unit: addr.unit,
      uniqueCode: addr.uniqueCode,
    }));
  }, [addr.unit]);

  return (
    <Container>
      <div
        style={{ borderTop: '2px solid' }}
        className="
          align-self-end
          py-2
          border-primary"
      >
        <h4>
          Connection Address
        </h4>
        <Form>
          <Row className="mb-2">
            <Form.Group controlId="formGridCity">
              <Form.Label>Unit * / Apartment *</Form.Label>
              <Form.Control
                className="input-with-radius"
                defaultValue={addr.unit}
                {...register('unit', {
                  validate: (value: string) => {
                    if (!addr.mdu && !signup.mdu) {
                      return true;
                    }
                    if (String(value).length <= 10) {
                      if (String(value).match(/^[a-zA-Z0-9]+$/)) {
                        return true;
                      }
                      return 'unit number must be numerical';
                    }
                    return 'unit number cannot be longer than 10 symbols';
                  },
                  required: addr.mdu ? 'unit is required' : undefined,
                })}
              />
              {errors?.unit && (
              <Form.Text className="text-info">
                {errors.unit.message}
              </Form.Text>
              )}
            </Form.Group>
          </Row>

          <Row className="mb-2">
            <Form.Group controlId="formGridZip">
              <Form.Label>Street name</Form.Label>
              <Form.Control
                className="input-with-radius"
                readOnly
                defaultValue={`${addr.street_number} ${addr.street}`}
              />
            </Form.Group>
          </Row>
          <Row className="mb-2">
            <Form.Group controlId="formGridCity">
              <Form.Label>Suburb / City</Form.Label>
              <Form.Control
                className="input-with-radius"
                readOnly
                defaultValue={addr.suburb}
              />
            </Form.Group>
          </Row>
          <Row className="mb-2">
            <Form.Group as={Col} controlId="formGridZip">
              <Form.Label>Post Code</Form.Label>
              <Form.Control
                readOnly
                className="input-with-radius"
                defaultValue={addr.postcode}
              />
            </Form.Group>

            <Form.Group as={Col} controlId="formGridState">
              <Form.Label>State</Form.Label>
              <Form.Select
                className="input-with-radius"
                defaultValue={addr.state}
                {...register('state', { value: addr.state, disabled: true })}
              >
                <option>VIC</option>
                <option>QLD</option>
                <option>NSW</option>
                <option>ACT</option>
                <option>TAS</option>
                <option>SA</option>
                <option>WA</option>
                <option>NT</option>
              </Form.Select>
              {errors.state && (
              <Form.Text className="text-info">
                {errors.state.message}
              </Form.Text>
              )}
            </Form.Group>
          </Row>
        </Form>
      </div>
    </Container>
  );
};

export default AddressInput;
